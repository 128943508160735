<template>
    <!--新增对话框-->
    <el-dialog
            title="编辑常量数据配置"
            :visible.sync="dialogVisible"
            width="800px"
            append-to-body
            :close-on-click-modal="false"
            :before-close="handleClose">

        <el-form :model="editForm" :rules="editFormRules" ref="editForm" label-width="100px" class="demo-editForm">
            <el-form-item label="类型描述" prop="describe" label-width="100px" >
                <el-input v-model="editForm.describe" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="是否为图片" prop="isPicture" label-width="100px" >
                <el-radio-group v-model="editForm.isPicture" >
                    <el-radio disabled :label="0">否</el-radio>
                    <el-radio disabled :label="1">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="类型值" prop="value" label-width="100px" v-if="editForm.isPicture == 0">
                <el-input v-model="editForm.value" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="群二维码" prop="value" label-width="100px" v-if="editForm.isPicture == 1">
                <VploadImgTwo :limit="1" v-bind:file-list="fileList" :type="0" @submitImg="getImgList" ></VploadImgTwo>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('editForm')">立即创建</el-button>
                <el-button @click="resetForm('editForm')">重置</el-button>
            </el-form-item>
        </el-form>

    </el-dialog>
</template>

<script>
import VploadImgTwo from "../inc/VploadImgTwo";
    export default {
        name: "UpdateConfigType",
        components:{
            VploadImgTwo
        },
        data(){
            return{
                editForm:{},
                fileList:[],
                editFormRules: {
                    value: [
                        {required: true, message: '请选择群二维码', trigger: 'blur'}
                    ]
                },
                dialogVisible: false,
            }
        },
        methods:{
            change(val) {
                this.editForm.describe=val;
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.dialogVisible = false
                this.editForm = {}
                this.fileList=[];
            },
            handleClose() {
                this.resetForm('editForm')
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    console.log("valid",valid)
                    if (valid) {
                        this.$axios.post('/admin/configType/updConfigType' , this.editForm)
                            .then(res => {
                                this.$message({
                                    showClose: true,
                                    message: '恭喜你，操作成功',
                                    type: 'success',
                                    onClose:() => {
                                        this.$emit('refreshDataList')
                                    }
                                });
                                this.dialogVisible = false
                                this.resetForm(formName)
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getImgList(url,add) {
                if (add==1){
                    this.editForm.value=url
                }else if (add==0){
                    this.editForm.value='';
                }

            },
            init(id){
                this.dialogVisible=true;
                if (id!=null&&id!=''){
                    this.$axios.post('/admin/configType/getConfigTypeInfo', {id:id}).then(res => {
                        this.editForm = res.data
                        var filess={url:res.data.value}
                        this.fileList.push(filess)
                    })
                }
            },
        }
    }
</script>

<style scoped>

</style>
