<template>
    <div>
        <el-form :inline="true">
            <el-form-item label="创建时间：" label-width="100">
                <el-date-picker
                        v-model="searchForm.createTime"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy年MM月dd日"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="是否为图片" label-width="100">
                <el-select v-model="searchForm.isPicture" clearable placeholder="选择是否为图片">
                    <el-option label="否" value="0"></el-option>
                    <el-option label="是" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button @click="getConfigType" type="success">搜索</el-button>
            </el-form-item>
        </el-form>

        <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                border
                stripe
                @selection-change="handleSelectionChange">


            <el-table-column
                    label="类型对应的值"
                    align="center"
                    width="200" >
                    <template slot-scope="scope">
                        <p v-if="scope.row.isPicture === 0">{{scope.row.value}}</p>
                        <el-image
                            v-if="scope.row.isPicture === 1"
                            style="width: 50px; height: 50px"
                            :src="scope.row.value"
                            fit="scale-down"></el-image>
                    </template>
            </el-table-column>
            <el-table-column
                    label="类型描述"
                    align="center"
                    prop="describe"
                    width="350">
            </el-table-column>
            <el-table-column
                    prop="createTime"
                    align="center"
                    width="200"
                    label="创建时间"
            >
            </el-table-column>
            <el-table-column
                    prop="isPicture"
                    align="center"
                    label="是否为图片"
                    width="150">
                <template slot-scope="scope">
                    <el-tag size="small" v-if="scope.row.isPicture === 0" type="info">否</el-tag>
                    <el-tag size="small" v-else-if="scope.row.isPicture === 1" type="success">是</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                    prop="icon"
                    align="center"
                    width="360"
                    fixed="right"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="UpdateConfigTypeButton(scope.row.id)" v-if="hasAuth('sys:configType:update')">编辑</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                layout="total, sizes, prev, pager, next, jumper"
                :page-sizes="[10, 20, 50, 100]"
                :current-page="current"
                :page-size="size"
                :total="total">
        </el-pagination>
        <UpdateConfigType ref="UpdateConfigType" v-if="UpdateConfigTypevisible" @refreshDataList="getConfigType"></UpdateConfigType>
    </div>
</template>

<script>
    import UpdateConfigType from "./UpdateConfigType";
    export default {
        name: "ConfigType",
        components:{
            UpdateConfigType
        },
        data(){
            return{
                searchForm:{},
                delBtlStatu:true,
                tableData:[],
                multipleSelection:[],
                total: 0,
                size: 10,
                current: 1,
                UpdateConfigTypevisible: false,
            }
        },
        created() {
            this.getConfigType();
        },
        methods:{
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.delBtlStatu = val.length == 0
            },

            handleSizeChange(val) {
                this.size = val
                this.getConfigType()
            },
            handleCurrentChange(val) {
                this.current = val
                this.getConfigType()
            },
            //获取常量数据配置列表
            getConfigType() {
                var startTime='';
                var endTime='';
                if(this.searchForm.createTime!=undefined){
                    startTime=this.searchForm.createTime[0];
                    endTime=this.searchForm.createTime[1];
                }
                this.searchForm.startTime=startTime
                this.searchForm.endTime=endTime;
                this.searchForm.pageNo=this.current
                this.searchForm.pageSize=this.size
                this.$axios.post("/admin/configType/getConfigTypePage", this.searchForm).then(res => {
                    this.tableData = res.data.records
                    this.size = res.data.size
                    this.current = res.data.pages
                    this.total = res.data.total
                })
            },
            UpdateConfigTypeButton(id){
                this.UpdateConfigTypevisible=true;
                this.$nextTick(()=>{
                    this.$refs.UpdateConfigType.init(id);
                })
            },
        }
    }
</script>

<style scoped>
    .el-button{
        padding: 0 10px;
        height: 35px;
        line-height:35px;
        font-size:15px;
        margin-right: 10px;
    }
</style>
